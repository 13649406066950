import React, { memo, useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext";
import { useRouter } from "next/router";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { OptionTypeBase } from "react-select";
import OtpModal from "../OtpModal";
import PurchasesForContactModal from "../purchasesForContactModal";
import CurrentPruchaseConfirmationModal from "../CurrentPruchaseConfirmationModal";
import AlertModal from "../AlertModal";
import { useOrgTheme } from "../../context/ThemeContext";
import CommonService from "../../services/common.service";
import { UtilityProvider } from "../../interfaces/common.interface";
import { Address } from "../Shared/PlacesAutocomplete";
import { JOB_TYPE } from "../../enums/jobType.enum";
import LeadService from "../../services/lead.service";
import { LeadOnly } from "../../interfaces/lead.interface";
import {
  LEAD_ACTION_TYPE,
  useLeadContext,
  useLeadDispatchContext,
} from "../../context/LeadContext";
import {
  getLeadData,
  setDesignUUID,
  setLeadData,
  storFromURLParams,
} from "../../utils/common.function.utils";
import { LEAD_STATUS } from "../../enums/leadStatus.enum";
import EstimateFormLayout from "./FormLayout";
import EstimateFormSliderLayout from "./SliderLayout";
import EstimateFormLayoutBlog from "./FormLayoutBlog";
import { toast } from "react-toastify";
import { GTMCustomEvents } from "../../enums/GTMCustomEvents.enum";
import { getLeadDataForGTM, pushToDataLayer } from "../../utils/gtm.utils";
import { clearLocaleStorageInEstimateSection } from "../../utils/page.utils";

interface Props {
  layout: "slider" | "form" | "blogform";
}

export interface FormProps {
  formik: any;
  onAddressChange: (val: Address) => void;
  onAddressBlur: () => void;
  utilityProviderOptions: OptionTypeBase[];
  showSelectLoader: boolean;
  setSwitch: (val: boolean) => any | null;
  isSwitchOn: boolean;
  isSubmitDisabled: () => boolean;
}

const commonService = new CommonService();
const leadService = new LeadService();

const initialUtilityProvider = [
  { label: "Select Utility Provider", value: "", isDisabled: true },
];
const otherOpt = { label: "Other", value: "other" };

function calculateAvgMonthlyBill(usage: number, utilityCost: number) {
  return (usage * utilityCost) / (12 * 100);
}
function calculateUsage(avgMonthlyBill: number, utilityCost: number) {
  return (avgMonthlyBill * 12 * 100) / utilityCost;
}
const defaultUtilityCost = 26.5; // in Cents
const GetEstimateForm = ({ layout }: Props) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const annualBill = !isSwitchOn;
  const { isServiceAvailableAt, getOrganizationId, getHostnameID } =
    useOrgTheme();
  const { setloader, setUserData } = useContext(UserContext);
  const [loading, setLoadingData] = useState<any>(false);

  const route = useRouter();
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [leadResponse, setLeadResponse] = useState({});
  const [teriffData, setTeriff] = useState<OptionTypeBase[]>([
    ...initialUtilityProvider,
    otherOpt,
  ]);
  const [allPurchasesForContact, setAllPurchasesForContact] = useState<any>([]);
  const [openPurchasesForContactModal, setOpenPurchasesForContactModal] =
    useState(false);
  const [
    openCurrentPurchaseConfirmationModal,
    setOpenCurrentPurchaseConfirmationModal,
  ] = useState<boolean>(false);

  const [instantEstimate, setInstantEstimate] = useState<boolean>(false);
  const [instantEstimateForm, setInstantEstimateForm] = useState<any>({});
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);
  const [utilityCost, setUtilityCost] = useState<number>(defaultUtilityCost);

  const leadDisapatcher = useLeadDispatchContext();
  const lead = useLeadContext();

  function closeOtpModalMethod() {
    setOpenOtpModal(false);
  }

  function continueAsNew() {
    // localStorage.clear();
    clearLocaleStorageInEstimateSection();
    getEstimate(true);
    closeOtpModalMethod();
  }

  useEffect(() => {
    if (allPurchasesForContact.length) {
      closeOtpModalMethod();
      openPurchasesForContactModalMethod();
    }
  }, [allPurchasesForContact]);

  const minKwh = Math.ceil(calculateUsage(50, utilityCost));
  const maxKwh = 50000;
  const formik = useFormik({
    initialValues: {
      address: {
        full_address: "",
        street: "",
        city: "",
        zip: "",
        state: "",
        lat: "",
        lng: "",
        state_code: "",
      },
      utility_id: "",
      avg_monthly_bill: "",
    },
    validationSchema: yup.object({
      address: yup.object({
        full_address: yup.string().required("Address is required"),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zip: yup.string().required("Zipcode is required"),
        street: yup.string().nullable(),
      }),

      utility_id: yup.string().required("Utility Provider is required"),
      avg_monthly_bill: annualBill
        ? yup
            .number()
            .required("Bill Amount is required")
            .min(50, "Bill Amount must be atleast $50")
            .max(10000, "Bill Amount should not exceed $10,000")
        : yup
            .number()
            .required("Annual kwh is required")
            .min(minKwh, `Annual kwh must be atleast ${minKwh} kwh`)
            .max(maxKwh, `Annual kwh should not exceed ${maxKwh} kwh`),
    }),
    onSubmit: () => {
      getEstimate();
    },
  });

  function handleAddressBlur() {
    formik.setFieldTouched("address", true, true);
  }

  function handleAddressChange(selectedAddress: Address) {
    const { address, latlong, streetAddress, city, state, zipcode, stateCode } =
      selectedAddress;

    const addressFieldValue = {
      full_address: address,
      city,
      state,
      zip: zipcode,
      lat: latlong.lat.toString(),
      lng: latlong.lng.toString(),
      street: streetAddress,
      state_code: stateCode,
    };
    formik.setFieldTouched("address", true, true);
    formik.setFieldValue("address", addressFieldValue, true);

    if (!zipcode || !state || !address) {
      return;
    }

    fetchUtilityProvider(state, zipcode);
  }
  async function fetchUtilityProvider(state: string, zip_code: string) {
    try {
      setLoadingData(true);
      setTeriff([...initialUtilityProvider, otherOpt]);
      const { data } = await commonService.getUtiltyProvider({
        state,
        zip_code,
        organization_id: `${getOrganizationId()}`,
      });
      setLoadingData(false);
      if (!data?.results?.length) {
        return;
      }
      // const { utility_cost } = data || {};
      // setUtilityCost(utility_cost > 0 ? utility_cost : defaultUtilityCost);
      setUtilitySelectOptions(data?.results || []);
    } catch (error) {
      setLoadingData(false);
    }
  }

  function setUtilitySelectOptions(data: UtilityProvider[]) {
    const utilityProviders = data.map((item: UtilityProvider) => ({
      label: item.name,
      value: item.lseId,
    }));
    const utilityProviderOptions = [
      ...initialUtilityProvider,
      ...utilityProviders,
      otherOpt,
    ];
    setTeriff(utilityProviderOptions);
  }

  // const calculateAnnualUssage = () => {
  //   const { avg_monthly_bill }: any = formik.values;
  //   const cost = utilityCost > 0 ? utilityCost : 0;
  //   const bill = avg_monthly_bill * 12 * 100;
  //   let usage: number = bill / cost;
  //   return usage;
  // };

  const getEstimate = async (continueAsNewCheck = false) => {
    if (!formik.values) {
      return;
    }
    // console.log("calculateAnnualUssage()");
    // console.log(calculateAnnualUssage());
    // 2014.7750167897918

    const payload: any = { ...formik.values };
    if (!annualBill) {
      payload.avg_monthly_bill = calculateAvgMonthlyBill(
        Number(payload.avg_monthly_bill),
        utilityCost
      );
    }
    storFromURLParams();
    const { address, utility_id, avg_monthly_bill } = payload;
    const utility_name =
      teriffData.find((item) => item.value === utility_id)?.label || "";
    let currentURL = window.location.href;
    let everflow_trans_id: any = document.getElementById("everflow_trans_id");

    everflow_trans_id = everflow_trans_id?.value;

    if (isServiceAvailableAt(address.state_code)) {
      let payload: any = {
        ...address,
        utility_id: utility_id.toString(),
        utility_name,
        utility_cost:utilityCost,
        avg_monthly_bill,
        from_url: currentURL,
        organization_id: +getOrganizationId(),
        job_type: JOB_TYPE.SOLAR,
        continue_as_new: continueAsNewCheck,
        host_name_id: getHostnameID(),
      };
      if (everflow_trans_id) {
        payload["everflow_trans_id"] = everflow_trans_id;
      }
      createNewLead(payload);
    } else {
      setInstantEstimate(true);
      const instantEstimateForm = {
        ...address,
        avg_monthly_bill,
        utility_name,
        utility_id,
        instantEstimate: true,
        utility_cost: utilityCost,
      };

      setInstantEstimateForm(instantEstimateForm);
      if (isCurrentPurchaseExit(instantEstimateForm)) {
        setOpenCurrentPurchaseConfirmationModal(true);
      } else {
        setloader(true);
        setLeadData(instantEstimateForm);
        const isInstantEstimateFormSubmitted =
          localStorage.getItem("instantEstimateFormSubmitted") === "true";
        if (isInstantEstimateFormSubmitted) {
          setDesignUUID(null);
          localStorage.removeItem("instantEstimateFormSubmitted");
        }
        route.prefetch("/instant-estimate");
        route.push({ pathname: "/instant-estimate" });
      }
    }
  };

  async function createNewLead(payload: any) {
    try {
      setloader(true);
      const response = await leadService.createLead(payload);
      if (!response) {
        setloader(false);
        return;
      }

      if (response?.otp_sent || response?.recipient_type) {
        setLeadResponse(response);
        setOpenOtpModal(true);
        setloader(false);
        toast.success(response.message);
        return;
      }

      if (response?.id) {
        pushToGTM({...payload,...response});
        navigate(response);
      }
    } catch (err) {
      setloader(false);
      handleErrors(err as Error);
    }
  }

  function handleErrors(error: Error) {
    if (error?.message && error.message.includes("phone")) {
      setOpenAlertModal(true);
      return;
    }
    toast.error(error?.message || "Something went wrong.");
  }

  function navigate(responseData: LeadOnly) {
    setUserData(responseData);
    leadDisapatcher({ type: LEAD_ACTION_TYPE.RESER_LEAD });
    leadDisapatcher({
      type: LEAD_ACTION_TYPE.UPDATE_LEAD,
      payload: responseData,
    });
    if (isCurrentPurchaseExit(responseData)) {
      setloader(false);
      setOpenCurrentPurchaseConfirmationModal(true);
    } else {
      handleNavigationBasedOnStatus(responseData);
    }
  }

  function isCurrentPurchaseExit(currentData: any) {
    const leadData = getLeadData();
    if (!leadData) {
      return false;
    }

    const isInstantLead = !!leadData?.instantEstimate;

    //  check Instant Estimate
    if (currentData.instantEstimate && !isInstantLead) return true;

    if (!currentData.instantEstimate && isInstantLead) return true;

    if (currentData.instantEstimate && isInstantLead) {
      if (
        currentData.full_address === leadData.full_address &&
        currentData.avg_monthly_bill === leadData.avg_monthly_bill &&
        currentData.utility_name === leadData.utility_name
      ) {
        return false;
      }
      return true;
    }

    return leadData.id !== currentData.id;
  }

  async function navigateToNewPurchase() {
    storFromURLParams();
    if (openCurrentPurchaseConfirmationModal) {
      setOpenCurrentPurchaseConfirmationModal(false);
    }
    if (instantEstimate) {
      setloader(true);
      setLeadData(instantEstimateForm);
      route.prefetch("/instant-estimate");
      route.push({ pathname: "/instant-estimate" });
    } else {
      handleNavigationBasedOnStatus();
    }
  }

  function navigateToCurrentPurchase() {
    storFromURLParams();
    setOpenCurrentPurchaseConfirmationModal(false);
    setloader(true);
    const leadData = getLeadData();

    if (leadData && leadData?.instantEstimate) {
      const pathname = leadData.id
        ? "/instant-estimate-result"
        : "/instant-estimate";
      route.push({ pathname });
      return;
    }

    handleNavigationBasedOnStatus(leadData);
  }

  function handleNavigationBasedOnStatus(leadData?: LeadOnly) {
    const leadToSave = leadData || lead;
    if (!!Object.keys(leadToSave || {}).length) {
      setLeadData({
        ...leadToSave,
        avg_monthly_bill: formik?.values?.avg_monthly_bill,
        utility_cost: utilityCost,
        utility_id: formik?.values?.utility_id,
        utility_name:
          teriffData.find((item) => item.value === formik?.values?.utility_id)
            ?.label || "",
      });
    }

    setloader(true);
    const pathToNavigate = getPathToNavigate(leadToSave);
    route.prefetch(pathToNavigate);
    route.push({ pathname: pathToNavigate });
  }

  function getPathToNavigate(lead: LeadOnly) {
    if (lead.status === LEAD_STATUS.CONVERTED) {
      return "/account";
    }
    return "/estimate";
  }

  function openPurchasesForContactModalMethod() {
    setOpenPurchasesForContactModal(true);
  }

  function closePurchasesForContactModalMethod() {
    setOpenPurchasesForContactModal(false);
  }

  function closeAlertModalMethod() {
    setOpenAlertModal(false);
  }
  const setSwitch = (val: boolean) => {
    setIsSwitchOn(val);
    setTimeout(() => {
      formik.setFieldValue("avg_monthly_bill", "", true);
    }, 0);
  };

  const isSubmitDisabled = () => {
    return fetchingUtilityCost || !formik.isValid;
  };

  const getGTMDataForLead = (data: any) => {
    try{
      const { avg_monthly_bill, utility_name } = data || {};
      const gtmData:any = getLeadDataForGTM(data) || {};
      gtmData.avg_monthly_bill = avg_monthly_bill;
      gtmData.utility_provider = utility_name;
      if (!annualBill) {
        gtmData.annualKwh = formik?.values?.avg_monthly_bill; //Annual KWH
      }
      return gtmData;
    }catch(err){
      console.log("error in getGTMDataForLead",err)
    }
  };
  const pushToGTM = (data: any) => {
    try {
      const gtmData = getGTMDataForLead(data);
      const event = {
        event: GTMCustomEvents.NEW_LEAD_CREATED,
        data:gtmData,
      };
      pushToDataLayer(event);
    } catch (err) {
      console.log(err);
    }
  };

  const [fetchingUtilityCost,setFetchingUtilityCost] = useState(false)
  useEffect(()=>{
    if(!formik.values?.utility_id) return
    (async ()=>{
      try{
        setFetchingUtilityCost(true)
        const result = await commonService.getGenibilityUtilityCost({
          organization_id: `${getOrganizationId()}`,
          utility_id:formik.values?.utility_id
        })
        const utility_cost = result?.data || defaultUtilityCost;
        setUtilityCost(utility_cost);
      }catch(err){
        setUtilityCost(defaultUtilityCost);
      }finally{
        setFetchingUtilityCost(false)
      }
    })()
  },[formik.values?.utility_id])

  return (
    <>
      {layout === "form" && (
        <EstimateFormLayout
          formik={formik}
          onAddressBlur={handleAddressBlur}
          onAddressChange={handleAddressChange}
          showSelectLoader={loading}
          utilityProviderOptions={teriffData}
          setSwitch={setSwitch}
          isSwitchOn={isSwitchOn}
          isSubmitDisabled={isSubmitDisabled}
        />
      )}
      {layout === "slider" && (
        <EstimateFormSliderLayout
          formik={formik}
          onAddressBlur={handleAddressBlur}
          onAddressChange={handleAddressChange}
          showSelectLoader={loading}
          utilityProviderOptions={teriffData}
          setSwitch={setSwitch}
          isSwitchOn={isSwitchOn}
          isSubmitDisabled={isSubmitDisabled}
        />
      )}
      {layout === "blogform" && (
        <EstimateFormLayoutBlog
          formik={formik}
          onAddressBlur={handleAddressBlur}
          onAddressChange={handleAddressChange}
          showSelectLoader={loading}
          utilityProviderOptions={teriffData}
          setSwitch={setSwitch}
          isSwitchOn={isSwitchOn}
          isSubmitDisabled={isSubmitDisabled}
        />
      )}

      {/* OTP Modal Start */}
      <Modal
        preventScroll={false}
        isOpen={openOtpModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          closeOtpModalMethod();
        }}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog"
        }
        ariaHideApp={false}
      >
        <OtpModal
          leadResponse={leadResponse}
          setAlertModalOpen={() => {
            setOpenAlertModal(true);
          }}
          navigate={navigate}
          continueAsNew={continueAsNew}
          type="lead"
          close={closeOtpModalMethod}
          setAllPurchasesForContact={setAllPurchasesForContact}
        ></OtpModal>
      </Modal>
      {/* OTP Modal Ends */}

      {/* PurchasesForContactModal Start */}
      <Modal
        preventScroll={false}
        isOpen={openPurchasesForContactModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          closePurchasesForContactModalMethod();
        }}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog"
        }
        ariaHideApp={false}
      >
        <PurchasesForContactModal
          allPurchasesForContact={allPurchasesForContact}
          navigate={navigate}
          close={closePurchasesForContactModalMethod}
        ></PurchasesForContactModal>
      </Modal>
      {/* PurchasesForContactModal Ends */}
      <Modal
        preventScroll={false}
        isOpen={openAlertModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          closeAlertModalMethod();
        }}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay alert-modal"
        }
        ariaHideApp={false}
      >
        <AlertModal
          content="The mobile number you provided is invalid. Please create a new project in order to proceed with this address."
          buttonLabel="Create New Project"
          action={continueAsNew}
          close={closeAlertModalMethod}
        />
      </Modal>

      {/* openCurrentPurchaseConfirmationModal Start */}

      <Modal
        preventScroll={false}
        isOpen={openCurrentPurchaseConfirmationModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => setOpenCurrentPurchaseConfirmationModal(false)}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay leadformModal"
        }
        ariaHideApp={false}
      >
        <CurrentPruchaseConfirmationModal
          onContinue={navigateToNewPurchase}
          onOpenCurrentPurchase={navigateToCurrentPurchase}
        />
      </Modal>
      {/* openCurrentPurchaseConfirmationModal Ends */}
    </>
  );
};

export default memo(GetEstimateForm);
